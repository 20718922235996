<template>
    <v-container>
      <div style="display: flex; justify-content: center; align-items: center; height: 100vh; width: 100%;">
        <img src="/page7.png" style="width: 50%;">
      </div>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'PageSeven',
  
    data: () => ({
    }),
  };
  </script>
  
  <style>
  </style>