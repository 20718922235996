<template>
    <v-container style="max-width: none;">
      <img src="/3-1.png" style="width: 100%;">
      <img src="/3-2.png" style="width: 100%;">
      <img src="/3-3.png" style="width: 100%;">
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'PageTwo',
  
    data: () => ({
      //
    }),
  };
  </script>
  
  <style>
    /* your style code here */
  </style>