<template>
    <v-container fluid class="fill-height">
        <v-row class="fill-height" align="center" justify="center" style="margin-bottom: 89px;">
            <v-col cols="12" md="6">
                <p style="color: white; font-size: 24px;">Contact</p>
                <p style="color: white; font-size: 14px;">E-mail Info@jostudios.io</p>
                <p style="color: white; font-size: 14px; font-weight: 700; margin-top: 74px; margin-bottom: 74px;">Get in touch with us</p>
                <v-form> 
                    <v-text-field  v-model="to" label="E-mail" required class="custom-label"></v-text-field>
                    <v-text-field v-model="subject" label="Subject" required class="custom-label"></v-text-field>
                    <v-textarea v-model="body" label="Write Something" required class="custom-label"></v-textarea>
                    <v-btn @click="sendEmail" style="border-radius: 50px; border: 1px solid #FFF; color: #FFF; width: 172px; background-color: transparent;">SEND MASSAGE</v-btn>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default { 
  name: 'ContactForm',
  data() {
    return {
      to: '',
      subject: '',
      body: ''
    }
  },
  methods: {
    sendEmail() {
    }
  }
}
</script>

<style scoped>
::v-deep .custom-label .v-label {
  color: rgba(255, 255, 255, 0.50) !important;
}

::v-deep .custom-label::before {
  border-color: rgba(255, 255, 255, 0.50) !important;
}

::v-deep .custom-label .v-input__slot::before {
  border-color: rgba(255, 255, 255, 0.50) !important;
}

/* 입력된 텍스트 색상 변경 */
::v-deep .custom-label .v-text-field__slot input {
  color: white !important;
}
</style>


