<template>
  <v-row>
    <v-col>
      <h1 style="color: white; font-size: 12px; font-weight: 400; margin-top: 100px; margin-bottom: 32px; padding-left: 5%;">Compnay Intro</h1>
    </v-col>
    <v-col cols="12" style="padding-left: 10%;">
      <p style="color: white; font-size: 17px;">An AI-generated music created by<br>educating ideal music for deep<br>learning by professional<br>composers.</p>
      <p style="color: rgba(255, 255, 255, 0.60); font-size: 7px;">This is not just AI-generated music, it is the harmonious blend of technology<br>and the expertise of seasoned musicians.</p>
    </v-col>
    <v-col cols="12">
      <h2 style="color: white; font-size: 12px; font-weight: 400; margin-top: 134px; padding-left: 5%;">What We Do</h2>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'MainPageMobile',
};
</script>

<style>
</style>