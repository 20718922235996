<template>
    <v-container>
      <div style="display: flex; justify-content: center; align-items: center; height: 100vh;">
        <img src="/page4.png">
      </div>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'PageFour',
  
    data: () => ({
    }),
  };
  </script>
  
  <style>
  </style>