<template>
        <v-row>
      <v-col cols="12" class="d-flex justify-center align-center fill-height">
        <v-responsive aspect-ratio="16/9" style="position: relative;">
          <video src="/video/main-video.mp4" autoplay loop muted style="object-fit: cover;" @error="handleError"></video>
          <img src="/main_logo.png" alt="Main Logo" style="position: absolute; top: 10px; left: 10px; width: 5%; height: 9%;">
          <img src="/main_bottom_logo.png" alt="Main Bottom Logo" style="position: absolute; bottom: 10px; left: 10px; width: 15%; height: 10%;">
          <div style="position: absolute; top: 20px; right: 20px; color: white; font-size: 14px; font-weight: bold;">CONTACT</div>
        </v-responsive>
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
    name: 'MainVedio',
  };
  </script>
  
  <style>
  </style>