<template>
    <v-container style="max-width: none;">
      <img src="/dogM1.png" style="width: 100%;">
      <img src="/dogM2.png" style="width: 100%;">
      <img src="/dogM3.png" style="width: 100%;">
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'PageTwoMobile',
  
    data: () => ({
      //
    }),
  };
  </script>
  
  <style>
  </style>