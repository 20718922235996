<template>
    <v-container>
        <v-row justify-center>
        <v-col class="text-center" style="color: white; margin-bottom: 30px;">
          © 2023. JO STUDIOS. All right reserved.
        </v-col>
      </v-row>
    </v-container>
  </template>

<script>
export default {
  name: 'PageFive',

  data: () => ({
  }),
};
</script>

<style>
</style>