<template>
  <v-app id="app" dark>
    <v-main v-if="!isMobile" id="page2">
      <MainVedio/>
    </v-main>
    <v-main v-if="!isMobile" id="page2">
      <MainPage/>
    </v-main>
    <v-main v-if="!isMobile" id="page3">
      <Page2/>
    </v-main>
    <v-main v-if="!isMobile" id="page2">
      <Page4/>
    </v-main>
    <v-main v-if="!isMobile" id="page5">
      <Page5/>
    </v-main>
    <v-main v-if="!isMobile" id="page5">
      <Page6/>
    </v-main>
    <v-main v-if="!isMobile" id="page2">
      <Page7/>
    </v-main>
    <v-main v-if="!isMobile" id="page2">
      <ContactPage/>
    </v-main>
    <v-main v-if="!isMobile" >
      <Page9/>
    </v-main>
    <v-main v-if="!isMobile" id="music">
      <MusicPlayer/>
    </v-main>
    <v-main v-if="!isMobile" id="footer">
      <Footer/>
    </v-main>

    <!--모바일-->
    <mobile-only>
      <v-main v-if="isMobile" id="page2">
        <MainVedioMobile/>
      </v-main>
      <v-main v-if="isMobile" id="page2">
        <MainMobile/>
      </v-main>
      <v-main v-if="isMobile" id="page2">
      <MobilePage2/>
    </v-main>
    <v-main v-if="isMobile" id="page5">
      <MobilePage5/>
    </v-main>
    <v-main v-if="isMobile" id="page5">
      <MobilePage6/>
    </v-main>
    <v-main v-if="isMobile" id="page2">
      <MobilePage7/>
    </v-main>
    <v-main v-if="isMobile" id="page2">
      <Contact/>
    </v-main>
    <v-main v-if="isMobile" id="music">
      <MobileMusicPlayer/>
    </v-main>
      <v-main v-if="isMobile" id="footer">
      <Footer/>
    </v-main>
    </mobile-only>
  </v-app>
</template>

<script>
//웹
import MainVedio from './components/MainVedio';
import MainPage from './components/Main';
import Page2 from './components/Page2';
import Page4 from './components/Page4';
import Page5 from './components/Page5';
import Page6 from './components/Page6';
import Page7 from './components/Page7';
import ContactPage from './components/contactWeb';
import Page9 from './components/Page9';
import Footer from './components/Footer';
import MusicPlayer from './components/MusicPlayer';
//모바일
import MainVedioMobile from './components/mobile/MainVedioMobile';
import MobileOnly from './components/MobileOnly';
import MainMobile from './components/mobile/MainMobile';
import MobilePage2 from './components/mobile/MobilePage2';
import MobilePage5 from './components/mobile/MobilePage5';
import MobilePage6 from './components/mobile/MobilePage6';
import MobilePage7 from './components/mobile/MobilePage7';
import MobileMusicPlayer from './components/mobile/MusicPlayerMobile';
import Contact from './components/mobile/contact';

export default {
  name: 'App',

  components: {
    MainVedio,
    MainPage,
    Page2,
    Page4,
    Page5,
    Page6,
    Page7,
    ContactPage,
    Page9,
    Footer,
    MainVedioMobile,
    MusicPlayer,
    MobileOnly,
    MainMobile,
    MobilePage2,
    MobilePage5,
    MobilePage6,
    MobilePage7,
    Contact,
    MobileMusicPlayer,
  },

  data: () => ({
  }),

  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    },
  },

  methods: {
    handleError() {
      console.error('An error occurred while playing the video.');
    },
  },
};
</script>

<style>
  #app {
    background-color: rgba(17, 17, 17, 1);
  }
  #page2 {
    background-color: rgb(20, 20, 20);
  }
  #page5 {
    background-color: rgb(0, 0, 0);
  }
  video {
    width: 100%;
    height: 100%;
  }
  #music {
    background-color: #333;;
  }
  #footer {
    background-color: rgba(17, 17, 17, 1);
  }
</style>