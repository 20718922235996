<template>
    <v-container>
      <div style="display: flex; justify-content: center; align-items: center; margin-top: 135px; margin-bottom: 135px;">
        <img src="/page7logo.png" style="width: 70%;">
      </div>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'PageSeven',
  
    data: () => ({
    }),
  };
  </script>
  
  <style>
  </style>