<template>
  <v-container>
    <div class="video-container">
      <video src="/video/flow.mp4" autoplay loop muted></video>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'PageSix',
};
</script>

<style scoped>
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
  margin-bottom: 220px;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
