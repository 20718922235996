<template>
  <v-container>
    <v-row>
      <v-col style="margin-bottom: 350px;">
        <h1 style="text-align: left; color: white; font-size: 34px; font-weight: 400; width: 667px; margin: 0 auto;  margin-top: 350px; position: relative;">An AI-generated music created by educating ideal music for deep learning by professional composers.
          <span style="position: absolute; top: -80px; left: -50px; color: white; font-size: 24px;">Company Intro</span>
          <span style="position: absolute; bottom: -380px; left: -150px; color: white; font-size: 24px;">What We Do</span>
        </h1>
        <p style="text-align: center; margin: 0 auto; text-align: left; margin-top: 20px; color: rgba(255, 255, 255, 0.60); font-size: 14px; width: 667px">This is not just AI-generated music, it is the harmonious blend of technology
and the expertise of seasoned musicians.</p>
        <img src="/robot1.png" style="position: absolute; bottom: 0; left: 0; width: 20%; height: 50%;">
        <img src="/robot2.png" style="position: absolute; bottom: 0; right: 0; width: 20%; height: 50%;">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MainPage',

  data: () => ({
    //
  }),
};
</script>

<style>
</style>