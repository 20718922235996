<template>
    <v-row>
  <v-col cols="12" class="d-flex justify-center align-center fill-height">
    <v-responsive aspect-ratio="16/9" style="position: relative;">
      <video src="/video/mobileMain.mp4" autoplay loop muted style="object-fit: cover;" @error="handleError"></video>
      <img src="/main_logo.png" alt="Main Logo" style="position: absolute; top: 10px; left: 20px; width: 10%; height: 6%;">
      <img src="/main_bottom_logo.png" alt="Main Bottom Logo" style="position: absolute; bottom: 10px; left: 10px; width: 30%; height: 8%;">
      <div style="position: absolute; top: 20px; right: 20px; color: white; font-size: 8px; font-weight: bold;">CONTACT</div>
    </v-responsive>
  </v-col>
</v-row>
</template>

<script>
export default {
name: 'MainVedioMobile',
};
</script>

<style>
</style>