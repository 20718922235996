import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
// import firebase from 'firebase/app'
// import 'firebase/firestore'  

// const firebaseConfig = {
//   apiKey: "AIzaSyB9WYVHcBpnPlUFcapbvVyQRcU-SWnge2E",
//   authDomain: "jo-studios.firebaseapp.com",
//   projectId: "jo-studios",
//   storageBucket: "jo-studios.appspot.com",
//   messagingSenderId: "355653973344",
//   appId: "1:355653973344:web:00327a8ae2682584aeda05"
// };

// // Initialize Firebase
// firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
