<template>
    <v-container>
      <div style="display: flex; justify-content: center; align-items: center; height: 100vh; width: 100%;">
      <video src="/video/gramobile.mp4" autoplay loop muted style="width: 100%; height: 100%; object-fit: cover;"></video>
    </div>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'PageFive',
  
    data: () => ({
    }),
  };
  </script>
  
  <style>
  </style>