<template>
  <v-app>
    <v-main>
      <v-container>
        <div style="display: flex; justify-content: center; align-items: center; height: 100vh; width: 100%;">
          <img src="/page9.png" style="width: 50%;">
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'PageSeven',
  data() {
  },
};
</script>

<style>
</style>