<template>
    <div v-if="isMobile">
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MobileOnly',
    computed: {
      isMobile() {
        return window.innerWidth <= 768;
      },
    },
  };
  </script>
  
  <style>
  </style>