<template>
  <v-container>
    <v-row class="d-flex flex-column align-center justify-center">
      <v-col class="d-flex flex-row align-center justify-center">
        <v-btn text fab class="mr-0" @click="openPlaylist" style="position: absolute; left: 20px;">
          <v-img src="/Queue.svg" aspect-ratio="1" max-width="24px"></v-img>
        </v-btn>

        <v-btn text fab class="mr-0" @click="toggleLoop">
          <v-img src="/repeat.svg" aspect-ratio="1" max-width="24px"></v-img>
        </v-btn>

        <div class="song-title">{{ currentSongTitle }}</div>
        <span class="mr-0 time-text" style="padding-right: 20px;">{{ currentTime }}</span>

        <v-progress-linear class="mx-0" style="width: 40%; height: 5px; margin-left: 10px; margin-right: 10px;"
          :value="progress" color="green" @click="changeProgress"></v-progress-linear>

        <span class="ml-0 mr-0 time-text" style="padding-left: 10px;">{{ duration }}</span>

        <v-btn text fab class="ml-0" @click="togglePlay">
          <v-img :src="isPlaying ? '/pause.svg' : '/play_circle.svg'" aspect-ratio="1" max-width="24px"></v-img>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="showPlaylistDialog" max-width="1000px" dark>
      <v-card>
        <v-tabs vertical style="border-right: white;" >
          <v-col style="border-right: 2px solid gray; background-color: black;">
          <v-tab @click="resetFilteredSongs" class="gray-text"
            style="background-color: black; color: white; font-weight: 900; width: 200px; height: 80px;">Mood</v-tab>
          <v-tab @click="resetFilteredSongs" class="gray-text"
            style="background-color: black; color: white; font-weight: 900; height: 80px;">Genres</v-tab>
          <v-tab @click="resetFilteredSongs" class="gray-text"
            style="background-color: black; color: white; font-weight: 900; height: 80px;">Theme</v-tab>
          <v-tab @click="resetFilteredSongs" class="gray-text"
            style="background-color: black; color: white; font-weight: 900; height: 80px;">Meditation</v-tab>
</v-col>
          <v-tab-item>
            <!-- Mood -->
            <v-row style="background-color: rgb(26, 26, 26);">
              <v-col  v-for="mood in ['Bright', 'Happy', 'Sad', 'Romantic', 'Fun/Exciting', 'Calm']" :key="mood" cols="4">
                <v-list-item @click="filterSongs('mood', mood)">
                  <v-list-item-content>
                    <v-list-item-title style="color: white; font-weight: 500; text-align: center;">{{ mood }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-col style="background-color: black; height: 300px; border-top: 2px solid gray;">
            <v-list style="background-color: black;" v-if="filteredSongs.length > 0" class="scrollable-list">
              <p style="margin-left: 15px; margin-top: 20px; font-weight: 700; color: white;">Select Music</p>
              <v-list-item-group v-model="selectedSong">
                <v-list-item v-for="song in filteredSongs" :key="song.id" @click="changeSong(song)">
                  <v-list-item-content>
                    <v-list-item-title style="color: white; width: 120%;">{{ song.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          </v-tab-item>
          <v-tab-item>
            <!-- Genres -->
            <v-row class="subcategory-background" style="background-color: rgb(26, 26, 26);">
              <v-col v-for="genres in ['Pop', 'Classic', 'World', 'Percussion', 'Funky', 'Retro',]" :key="genres"
                cols="4">
                <v-list-item @click="filterSongs('genres', genres)">
                  <v-list-item-content>
                    <v-list-item-title style="color: white; font-weight: 500; text-align: center;">{{ genres }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-col style="background-color: black; height: 300px; border-top: 2px solid gray;">
            <v-list style="background-color: black;" v-if="filteredSongs.length > 0" class="scrollable-list">
              <p style="margin-left: 15px; margin-top: 20px; font-weight: 700; color: white;">Select Music</p>
              <v-list-item-group v-model="selectedSong">
                <v-list-item v-for="song in filteredSongs" :key="song.id" @click="changeSong(song)">
                  <v-list-item-content>
                    <v-list-item-title style="color: white; width: 120%;">{{ song.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            </v-col>
          </v-tab-item>
          <v-tab-item>
            <!-- Theme -->
            <v-row class="subcategory-background" style="background-color: rgb(26, 26, 26);">
              <v-col  v-for="theme in ['Activity', 'Travel', 'Work', 'Lullaby', 'Fantasy', 'Love', 'Relex']" :key="theme"
                cols="3">
                <v-list-item  @click="filterSongs('theme', theme)">
                  <v-list-item-content>
                    <v-list-item-title style="color: white; font-weight: 500; text-align: center;">{{ theme }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-col style="background-color: black; height: 300px; border-top: 2px solid gray;">
            <v-list style="background-color: black;" v-if="filteredSongs.length > 0" class="scrollable-list">
              <p style="margin-left: 15px; margin-top: 20px; font-weight: 700; color: white;">Select Music</p>
              <v-list-item-group v-model="selectedSong">
                <v-list-item v-for="song in filteredSongs" :key="song.id" @click="changeSong(song)">
                  <v-list-item-content>
                    <v-list-item-title style="color: white; width: 120%;">{{ song.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            </v-col>
          </v-tab-item>
          <v-tab-item>
            <!-- Meditation -->
            <v-row class="subcategory-background" style="background-color: rgb(26, 26, 26);">
              <v-col v-for="meditation in ['Ambient', 'Buddist', 'CCM', 'Chant', 'Nature', 'New age', 'Focus']"
                :key="meditation" cols="3">
                <v-list-item @click="filterSongs('meditation', meditation)">
                  <v-list-item-content>
                    <v-list-item-title style="color: white; font-weight: 500; text-align: center;">{{ meditation }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-col style="background-color: black; height: 300px; border-top: 2px solid gray;">
            <v-list style="background-color: black;" v-if="filteredSongs.length > 0" class="scrollable-list">
              <p style="margin-left: 15px; margin-top: 20px; font-weight: 700; color: white;">Select Music</p>
              <v-list-item-group v-model="selectedSong">
                <v-list-item v-for="song in filteredSongs" :key="song.id" @click="changeSong(song)">
                  <v-list-item-content>
                    <v-list-item-title style="color: white; width: 120%;">{{ song.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            </v-col>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>


    <audio ref="audio" :src="audioUrl" @timeupdate="updateTime" @loadedmetadata="setDuration"></audio>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isPlaying: false,
      loop: false,
      audioUrl: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/BIg%20Eye.wav?alt=media&token=2c6de19e-32ed-4dfc-a21e-41be40cc507b',
      currentSongTitle: 'BIg Eye',
      currentTime: '00:00',
      duration: '00:00',
      progress: 0,
      showPlaylistDialog: false,
      songs: [
        { id: 1, title: 'accompany', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/accompany.wav?alt=media&token=7843a965-9d3f-4c08-94d0-442c0258aa0a', mood: 'Bright' },
        { id: 2, title: 'abuse', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/abuse.wav?alt=media&token=f12317da-821e-4e03-ac65-014383736797', mood: 'Bright' },
        { id: 3, title: 'accuracy', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/accuracy.wav?alt=media&token=ad4a24d0-2e07-4ee5-a77a-ac0921eb6690', mood: 'Bright' },
        { id: 4, title: 'acquire', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/acquire.wav?alt=media&token=b55056f7-0b2b-4a39-a6b5-ccd5419d665d', mood: 'Bright' },
        { id: 5, title: 'active', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/active.wav?alt=media&token=db16cef9-8c72-4c8d-becb-c415c50999fb', mood: 'Bright' },

        { id: 1, title: 'absorb', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/absorb.wav?alt=media&token=6532bd0c-b1e6-4147-9490-158ead796933', mood: 'Happy' },
        { id: 2, title: 'add', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/add.wav?alt=media&token=e4cf5f6b-48bc-4892-af46-10fac6e44634', mood: 'Happy' },
        { id: 3, title: 'adjust', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/adjust.wav?alt=media&token=3a3d571d-5f30-4bdc-92ef-ebffff84af64', mood: 'Happy' },
        { id: 4, title: 'admire', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/admire.wav?alt=media&token=102f5e01-3340-4a2a-bd80-a1d446119196', mood: 'Happy' },

        { id: 1, title: 'abandon', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/abandon.wav?alt=media&token=b2e14a5e-8deb-47ab-b32b-74ddf808039d', mood: 'Sad' },
        { id: 2, title: 'admit', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/admit.wav?alt=media&token=1649246d-655e-4fe0-852f-17f7cff985c5', mood: 'Sad' },
        { id: 3, title: 'age', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/age.wav?alt=media&token=d8691c3b-78c6-408a-bd61-cf40a9ef62b9', mood: 'Sad' },
        { id: 4, title: 'agency', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/agency.wav?alt=media&token=a668133a-fa9d-4bbb-87fa-5ac80fd686ea', mood: 'Sad' },

        { id: 1, title: 'Wind-song', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/Wind-song.wav?alt=media&token=162c4072-5185-4914-8524-7760e27540e2', mood: 'Romantic' },
        { id: 2, title: 'come', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/come.wav?alt=media&token=d1afb262-0627-4efe-acf5-098781fb0d80', mood: 'Romantic' },
        { id: 3, title: 'commit', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/commit.wav?alt=media&token=c66e43fb-2e14-45f2-81b9-91d7d3036d58', mood: 'Romantic' },

        { id: 1, title: 'TIME TRAVEL', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/TIME%20TRAVEL.wav?alt=media&token=0f148289-6d60-4676-a431-30d532977358', mood: 'Fun/Exciting' },
        { id: 2, title: 'commitment', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/commitment.wav?alt=media&token=85b47e4b-0491-44d1-9a88-6285da36b813', mood: 'Fun/Exciting' },
        { id: 3, title: 'common', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/come.wav?alt=media&token=d1afb262-0627-4efe-acf5-098781fb0d80', mood: 'Fun/Exciting' },


        { id: 1, title: 'Space', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/Space.wav?alt=media&token=0b18270c-0f96-484b-8d6d-cee41b5fde0e', mood: 'Calm' },


        { id: 1, title: 'Sleep Well', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/Sleep%20Well.wav?alt=media&token=117c2a82-d749-492a-a567-88a3be02721c', genres: 'Pop' },


        { id: 1, title: 'SUMMER', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/SUMMER.wav?alt=media&token=c544a548-1d5f-470d-bbfc-1f3afa026552', genres: 'Classic' },


        { id: 1, title: 'Rainbow', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/Rainbow.wav?alt=media&token=4e225571-6b50-47de-aa74-3d11926a9caf', genres: 'World' },


        { id: 1, title: 'RUN TO DREAM', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/RUN%20TO%20DREAM.wav?alt=media&token=1719b907-6faa-444e-8e75-92c51c53cf4d', genres: 'Percussion' },


        { id: 1, title: 'Point', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/Point.wav?alt=media&token=b7619058-52c6-4c92-82a4-7a19edd6e03d', genres: 'Funky' },


        { id: 1, title: 'Pieces', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/Pieces.wav?alt=media&token=bb16b02e-9f2a-4f59-9760-be7bf8e85aa3', genres: 'Retro' },


        { id: 1, title: 'Party outlaw', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/Party%20outlaw.wav?alt=media&token=81b95cea-6b47-4825-ad9a-f9a1f8eaa183', theme: 'Activity' },


        { id: 1, title: 'PURE', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/PURE.wav?alt=media&token=281f342d-fabe-4a66-99f6-a25055d1d465', theme: 'Travel' },


        { id: 1, title: 'OLD PHOTO ALBUM', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/OLD%20PHOTO%20ALBUM.wav?alt=media&token=083963c1-ad56-41fc-ba61-b3bf1aff0ab2', theme: 'Work' },


        { id: 1, title: 'Monkey jump', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/Monkey%20jump.wav?alt=media&token=8a20acb6-e73f-4c21-b597-7e5e1d4e75b0', theme: 'Lullaby' },


        { id: 1, title: 'Missing you', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/Missing%20you.wav?alt=media&token=35674ce9-4e6c-4c14-88f9-45d8f2318dd5', theme: 'Fantasy' },


        { id: 1, title: 'MISSING', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/MISSING.wav?alt=media&token=ac799239-fda8-4c18-97b0-3f8718e6ca21', theme: 'Love' },


        { id: 1, title: 'MIMOSA.wav', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/MIMOSA.wav?alt=media&token=51db1a82-7f27-4294-858c-15d8fb9e02cc', theme: 'Relex' },


        { id: 1, title: 'I WISH', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/I%20WISH.wav?alt=media&token=ef8d8a42-4f61-4875-bb57-d4f194a8bd27', meditation: 'Ambient' },


        { id: 1, title: 'I WISH SEE YOU AGIAN', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/I%20WISH%20SEE%20YOU%20AGIAN.wav?alt=media&token=05e8061e-349b-4e51-bc34-e111a330c829', meditation: 'Buddist' },


        { id: 1, title: 'GOOD NIGHT', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/GOOD%20NIGHT.wav?alt=media&token=6460d102-7ce0-4e96-86ff-d40e062cea64', meditation: 'CCM' },


        { id: 1, title: 'Evidence', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/Evidence.wav?alt=media&token=ffb1babe-b4ce-4bbe-a07d-a9bfbce91653', meditation: 'Chant' },


        { id: 1, title: 'English', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/English.wav?alt=media&token=64ec57df-2af6-4c42-94cb-6f7ab797cffc', meditation: 'Nature' },


        { id: 1, title: 'BIg Eye', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/BIg%20Eye.wav?alt=media&token=2c6de19e-32ed-4dfc-a21e-41be40cc507b', meditation: 'New age' },


        { id: 1, title: 'DARKNESS', url: 'https://firebasestorage.googleapis.com/v0/b/jo-studios.appspot.com/o/DARKNESS.wav?alt=media&token=561f48c7-0678-47ba-afb1-6306bdd57219', meditation: 'Focus' },

      ],
      selectedSong: null,

      filteredSongs: [],
      categories: {
        mood: ['Bright', 'Happy', 'Sad', 'Romantic', 'Fun/Exciting', 'Calm'],
        genres: ['Pop', 'Classic', 'World', 'Percussion', 'Funky', 'Retro',],
        theme: ['Activity', 'Travel', 'Work', 'Lullaby', 'Fantasy', 'Love', 'Relex'],
        meditation: ['Ambient', 'Buddist', 'CCM', 'Chant', 'Nature', 'New age', 'Focus'],
      }
    };
  },
  watch: {
  currentCategory: "resetFilteredSongs",
  replay: "resetChangeProgress"
},
  methods: {
    resetFilteredSongs() {
    this.filteredSongs = [];
    },
    resetChangeProgress() {
      this.$refs.audio.currentTime = 0;
    },
    beforeDestroy() {
      this.filteredSongs = [];
    },
    togglePlay() {
      if (this.isPlaying) {
        this.$refs.audio.pause();
      } else {
        this.$refs.audio.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    toggleLoop() {
      this.loop = !this.loop;
      this.$refs.audio.loop = this.loop;
    },
    updateTime(event) {
      const time = event.target.currentTime;
      this.currentTime = this.formatTime(time);
      this.progress = (time / event.target.duration) * 100;
    },
    setDuration(event) {
      this.duration = this.formatTime(event.target.duration);
    },
    changeProgress(event) {
      const x = event.clientX - event.target.getBoundingClientRect().left;
      const clickedValue = x / event.target.offsetWidth;
      this.$refs.audio.currentTime = clickedValue * this.$refs.audio.duration;
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    },
    openPlaylist() {
      this.showPlaylistDialog = true;
    },
    filterSongs(category, subcategory) {
      this.filteredSongs = this.songs.filter(song => song[category] === subcategory);
    },
    changeSong(song) {
      this.audioUrl = song.url;
      this.currentSongTitle = song.title;
      this.$refs.audio.load();
      this.showPlaylistDialog = false;
    },
  },

  mounted() {
    if (this.loop) {
      this.$refs.audio.loop = true;
    }
  },
};
</script>

<style scoped>
.time-text {
  font-size: 10px;
  color: white;
}

.song-title {
  font-size: 12px;
  color: white;
  position: absolute;
  top: 10px;
  left: 50%;
}
.scrollable-list {
  max-height: calc(4 * 44px);
  overflow-y: auto;
}
</style>
